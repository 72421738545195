import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const ErrorComponent = ({ errorText }) => (
    <Error>{errorText}</Error>
);

ErrorComponent.propTypes = {
    errorText: PropTypes.string,
}

export default ErrorComponent;

/*----------------- Styles -----------------*/
const Error = styled('div')`
    font-size: 14px;
    line-height: 21px;
    min-height: 21px;
    margin: 0 10px;
    color: ${({ theme }) => theme.errorColor};
`;