import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import { appName } from '../../../../config/app.config';
import { LOGOUT_USER_SUCCESS } from './auth.duck';
import * as categoriesService from '../utils/categoriesService';

export const moduleName = 'categories';
const prefix = `${appName}/${moduleName}`;

// Selectors
const selectCategories = state => state.get(moduleName);
export const loading = () =>
	createSelector(selectCategories, state => state.get('loading'));
export const errorMessage = () =>
	createSelector(selectCategories, state => state.get('errorMessage'));
export const allCategories = () =>
	createSelector(selectCategories, state => state.get('allCategories'));
export const categories = () =>
	createSelector(selectCategories, allCategories, (state, allCategories) => {
		const categories = state.get('categories');
		if (categories.length === 0) {
			return allCategories;
		}
		return categories;
	});
export const filters = () =>
	createSelector(selectCategories, state => ({
		pageSize: state.get('pageSize'),
		type: state.get('type'),
	}));
export const nextPageToken = () =>
	createSelector(selectCategories, state => state.get('nextPageToken'));
export const isLoadingMore = () =>
	createSelector(selectCategories, state => state.get('isLoadingMore'));
export const isAddCategoryInProgress = () =>
	createSelector(selectCategories, state =>
		state.get('isAddCategoryInProgress'),
	);
// Constants
export const GET_CATEGORIES_STARTED = `${prefix}/GET_CATEGORIES_STARTED`;
export const GET_CATEGORIES_SUCCESS = `${prefix}/GET_CATEGORIES_SUCCESS`;
export const GET_CATEGORIES_FAIL = `${prefix}/GET_CATEGORIES_FAIL`;
export const GET_CATEGORIES = `${prefix}/GET_CATEGORIES`;
export const GET_ALL_CATEGORIES_STARTED = `${prefix}/GET_ALL_CATEGORIES_STARTED`;
export const GET_ALL_CATEGORIES_SUCCESS = `${prefix}/GET_ALL_CATEGORIES_SUCCESS`;
export const GET_ALL_CATEGORIES_FAIL = `${prefix}/GET_ALL_CATEGORIES_FAIL`;
export const GET_SECTIONS = `${prefix}/GET_SECTIONS`;
export const ADD_CATEGORY = `${prefix}/ADD_CATEGORY`;
export const ADD_CATEGORY_STARTED = `${prefix}/ADD_CATEGORY_STARTED`;
export const ADD_CATEGORY_SUCCESS = `${prefix}/ADD_CATEGORY_SUCCESS`;
export const ADD_CATEGORY_FAIL = `${prefix}/ADD_CATEGORY_FAIL`;
export const DELETE_CATEGORY = `${prefix}/DELETE_CATEGORY`;
export const DELETE_CATEGORY_STARTED = `${prefix}/DELETE_CATEGORY_STARTED`;
export const DELETE_CATEGORY_SUCCESS = `${prefix}/DELETE_CATEGORY_SUCCESS`;
export const DELETE_CATEGORY_FAIL = `${prefix}/DELETE_CATEGORY_FAIL`;
export const FILTERS_CHANGED = `${prefix}/FILTERS_CHANGED`;
export const LOAD_MORE = `${prefix}/LOAD_MORE`;
export const LOAD_MORE_SUCCESS = `${prefix}/LOAD_MORE_SUCCESS`;
export const LOAD_MORE_FAIL = `${prefix}/LOAD_MORE_FAIL`;

// Reducer
export const initialState = fromJS({
	loading: false,
	isAddCategoryInProgress: false,
	isDeleteCategoryInProgress: false,
	loadingCategory: false,
	categories: [],
	allCategories: [],
	errorMessage: '',
	pageSize: '20',
	sectionName: '',
	nextPageToken: '',
	isLoadingMore: false,
});

/**
 *
 * @param {Object} state
 * @param {boolean} state.loading
 * @param {Array} state.categories
 * @param {string} state.errorMessage
 * @param {Object} action
 * @param {string} action.type
 * @param {array|string|object|undefined} [action.payload]
 * @returns {Object}
 */
export default function(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_CATEGORIES_STARTED:
		case GET_ALL_CATEGORIES_STARTED:
			return state.set('loading', true);
		case ADD_CATEGORY_STARTED:
			return state.set('isAddCategoryInProgress', true);
		case DELETE_CATEGORY_STARTED:
			return state.set('isDeleteCategoryInProgress', true);
		case GET_CATEGORIES_SUCCESS:
			return state
				.set('loading', false)
				.set('categories', payload.result)
				.set('nextPageToken', payload.nextPageToken);
		case GET_CATEGORIES_SUCCESS:
			return state.set('loading', false).set('categories', payload.result);
		case GET_ALL_CATEGORIES_SUCCESS:
			return state.set('allCategories', payload.result);
		case GET_CATEGORIES_FAIL:
		case GET_ALL_CATEGORIES_FAIL:
			return state.set('loading', false).set('errorMessage', payload);
		case ADD_CATEGORY_FAIL:
			return state
				.set('isAddCategoryInProgress', false)
				.set('errorMessage', payload);
		case DELETE_CATEGORY_FAIL:
			return state
				.set('isDeleteCategoryInProgress', false)
				.set('errorMessage', payload);
		case ADD_CATEGORY_SUCCESS:
			return state.set('isAddCategoryInProgress', false);
		case DELETE_CATEGORY_SUCCESS:
			return state
				.set('isDeleteCategoryInProgress', false)
				.set('categories', payload);
		case LOGOUT_USER_SUCCESS:
			return fromJS({});
		case FILTERS_CHANGED:
			const changedFilters = Object.keys(payload);
			return changedFilters.reduce(
				(acc, filter) => acc.set(filter, payload[filter]),
				state,
			);
		case LOAD_MORE:
			return state.set('isLoadingMore', true);
		case LOAD_MORE_SUCCESS:
			const { result, nextPageToken } = payload;
			return state
				.set('categories', state.get('categories').concat(result))
				.set('nextPageToken', nextPageToken)
				.set('isLoadingMore', false);
		case LOAD_MORE_FAIL:
			return state.set('errorMessage', payload).set('isLoadingMore', false);
		default:
			return state;
	}
}

// Action creators
export function fetchCategoriesRequest(payload) {
	return {
		type: GET_CATEGORIES,
		payload,
	};
}

export function fetchCategoriesStarted() {
	return {
		type: GET_CATEGORIES_STARTED,
	};
}

export function fetchCategoriesSuccess(payload) {
	return {
		type: GET_CATEGORIES_SUCCESS,
		payload,
	};
}

export function fetchCategoriesFail(payload) {
	return {
		type: GET_CATEGORIES_FAIL,
		payload,
	};
}

export function fetchAllCategoriesRequest() {
	return {
		type: GET_CATEGORIES,
		payload: 'all',
	};
}

export function fetchAllCategoriesStarted() {
	return {
		type: GET_ALL_CATEGORIES_STARTED,
	};
}

export function fetchAllCategoriesSuccess(payload) {
	return {
		type: GET_ALL_CATEGORIES_SUCCESS,
		payload,
	};
}

export function fetchAllCategoriesFail(payload) {
	return {
		type: GET_ALL_CATEGORIES_FAIL,
		payload,
	};
}

export function addCategoryRequest(payload) {
	return {
		type: ADD_CATEGORY,
		payload,
	};
}

export function filtersChangedRequest(payload) {
	return {
		type: FILTERS_CHANGED,
		payload,
	};
}

export const addCategoryStarted = () => ({ type: ADD_CATEGORY_STARTED });
export const addCategorySuccess = payload => ({
	type: ADD_CATEGORY_SUCCESS,
	payload,
});
export const addCategoryFail = payload => ({
	type: ADD_CATEGORY_FAIL,
	payload,
});

export const deleteCategoryRequest = payload => ({
	type: DELETE_CATEGORY,
	payload,
});

export const deleteCategoryStarted = () => ({ type: DELETE_CATEGORY_STARTED });
export const deleteCategorySuccess = payload => ({
	type: DELETE_CATEGORY_SUCCESS,
	payload,
});
export const deleteCategoryFail = payload => ({
	type: DELETE_CATEGORY_FAIL,
	payload,
});
export const loadMoreCategoriesRequest = () => ({
	type: LOAD_MORE,
});
export const loadMoreSuccess = payload => ({
	type: LOAD_MORE_SUCCESS,
	payload,
});
export const loadMoreFail = payload => ({
	type: LOAD_MORE_FAIL,
	payload,
});
// Sagas
export function* getCategoriesSaga({ payload }) {
	if (payload === 'all') {
		yield put(fetchAllCategoriesStarted());
		try {
			let { result } = yield call(categoriesService.getAllCategories);
			// result = null is returned if there are no categories;
			if (!result) {
				result = [];
			}
			yield put(fetchAllCategoriesSuccess({ result }));
		} catch (e) {
			yield put(fetchAllCategoriesFail(e.message));
			console.error(
				`getCategoriesSaga with payload: ${JSON.stringify(
					payload,
				)} error: ${e}`,
			);
		}
	} else {
		yield put(fetchCategoriesStarted());
		try {
			const currentFilters = yield select(filters());
			let { result, nextPageToken } = yield call(
				categoriesService.getCategories,
				{
					...currentFilters,
					...payload,
				},
			);
			// result = null is returned if there are no categories;
			if (!result) {
				result = [];
			}
			yield put(fetchCategoriesSuccess({ result, nextPageToken }));
		} catch (e) {
			yield put(fetchCategoriesFail(e.message));
			console.error(
				`getCategoriesSaga with payload: ${JSON.stringify(
					payload,
				)} error: ${e}`,
			);
		}
	}
}

export function* addCategorySaga({ payload }) {
	yield put(addCategoryStarted());
	try {
		const response = yield call(categoriesService.addCategory, payload);
		yield put(addCategorySuccess(response));
		const query = yield select(filters());
		yield* getCategoriesSaga({ payload: query });
	} catch (e) {
		console.error(
			`addCategorySaga with payload: ${JSON.stringify(payload)} error: ${e}`,
		);
		yield put(addCategoryFail());
	}
}

export function* deleteCategorySaga({ payload }) {
	yield put(deleteCategoryStarted());
	try {
		yield call(categoriesService.deleteCategory, payload);
		const oldCategories = yield select(categories());
		const newCategories = oldCategories.filter(
			category => category.id !== payload,
		);
		yield put(deleteCategorySuccess(newCategories));
	} catch (e) {
		yield put(deleteCategoryFail(e));
		console.error(
			`deleteCategorySaga with payload: ${JSON.stringify(payload)} error: ${e}`,
		);
	}
}

export function* filtersChangedSaga({ payload }) {
	yield* getCategoriesSaga(payload);
}

export function* loadMoreSaga() {
	const token = yield select(nextPageToken());
	if (token) {
		const query = yield select(filters());
		try {
			let { result, nextPageToken } = yield call(
				categoriesService.getCategories,
				{
					...query,
					nextPageToken: token,
				},
			);
			// result = null returns if there are no sections
			if (!result) {
				result = [];
			}
			yield put(loadMoreSuccess({ result, nextPageToken }));
		} catch (e) {
			yield put(loadMoreFail(e.message));
			console.error(`loadMoreSaga error: ${e}`);
		}
	}
}

function* filterChanged() {
	yield takeEvery(FILTERS_CHANGED, filtersChangedSaga);
}

function* getCategories() {
	yield takeEvery(GET_CATEGORIES, getCategoriesSaga);
}

function* addCategory() {
	yield takeEvery(ADD_CATEGORY, addCategorySaga);
}

function* deleteCategory() {
	yield takeEvery(DELETE_CATEGORY, deleteCategorySaga);
}

export function* loadMore() {
	yield takeEvery(LOAD_MORE, loadMoreSaga);
}

export function* categoriesRootSaga() {
	yield all([
		getCategories(),
		addCategory(),
		deleteCategory(),
		filterChanged(),
		loadMore(),
	]);
}
