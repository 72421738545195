enum dayMonth {
	cur = 'cur',
	prev = 'prev',
	next = 'next',
}

interface DayProps {
	month: dayMonth;
	day: number;
}

export const getDaysInMonth = (date: Date, prev?: boolean): number => {
	const currentMonth = date.getMonth();
	const currentYear = date.getFullYear();
	const daysInMonth = new Date(
		currentYear,
		currentMonth + (prev ? 0 : 1),
		0,
	).getDate();
	return daysInMonth;
};

export const getWeekPeriod = (
	date?: Date,
): {
	from: Date;
	to: Date;
} => {
	const now = date || new Date();
	const day = now.getDay();
	const year = now.getFullYear();
	const month = now.getMonth();
	const dayOfMonth = now.getDate();
	const from = new Date(year, month, dayOfMonth - day + 1); // staring from monday
	const to = new Date(year, month, dayOfMonth - day + 7);
	return {
		from,
		to,
	};
};

export const dateToString = (date: Date): string =>
	date && `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

export const getMonthPeriod = (
	date?: Date,
): {
	from: Date;
	to: Date;
} => {
	const now = date || new Date();
	const year = now.getFullYear();
	const month = now.getMonth();
	const daysInMonth = new Date(year, month + 1, 0).getDate();
	return {
		from: new Date(year, month, 1),
		to: new Date(year, month, daysInMonth),
	};
};

export const fillRowsInCalendar = (date: Date) => {
	let rows = [];
	const daysInCurrentMonth = getDaysInMonth(date);
	const generateRow = (
		rowIndex: number,
		daysInCurrentMonth: number,
		firstDay: number,
		date: Date,
	) => {
		let row: Array<DayProps> = [];
		if (rowIndex === 0) {
			if (firstDay !== 0) {
				const daysInPrevMonths = getDaysInMonth(date, true);
				for (let i = 0; i < firstDay; i++) {
					row.push({
						day: daysInPrevMonths - firstDay + i + 1,
						month: dayMonth.prev,
					});
				}
				for (let i = 0; i < 7 - firstDay; i++) {
					row.push({
						day: i + 1,
						month: dayMonth.cur,
					});
				}
			} else {
				for (let i = 0; i < 7; i++) {
					row.push({
						day: i + 1,
						month: dayMonth.cur,
					});
				}
			}
			return row;
		}
		const daysLeftInCurrentMonth =
			daysInCurrentMonth - (7 - firstDay) - 7 * (rowIndex - 1);
		if (daysLeftInCurrentMonth >= 7) {
			for (let i = 0; i < 7; i++) {
				row.push({
					day: daysInCurrentMonth - daysLeftInCurrentMonth + 1 + i,
					month: dayMonth.cur,
				});
			}
			return row;
		}
		for (let i = 0; i < 7; i++) {
			if (daysLeftInCurrentMonth >= i + 1) {
				row.push({
					day: daysInCurrentMonth - daysLeftInCurrentMonth + 1 + i,
					month: dayMonth.cur,
				});
			}
		}
		const rowLength = row.length;
		if (rowLength < 7) {
			for (let i = 0; i < 7 - rowLength; i++) {
				row.push({
					day: i + 1,
					month: dayMonth.next,
				});
			}
		}
		return row;
	};
	const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
	for (let i = 0; i < 5; i++) {
		rows.push(generateRow(i, daysInCurrentMonth, firstDay, date));
	}
	return rows;
};
