import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ErrorField from '../FormError';
import EmailIcon from '../icons/Email';
import PasswordIcon from '../icons/Password';

const InputTypes = {
	text: 'text',
	email: 'email',
	password: 'password',
};

const Input = ({
	type,
	label,
	name,
	value,
	errors,
	onChange,
	onFocus,
	onBlur,
	onClick,
	styles,
	showSuggestion,
}) => {
	const isEmpty = !value;
	const isErrored = errors && Object.keys(errors).some(key => errors[key]);
	const errorMessages = () => {
		if (errors) {
			const keys = Object.keys(errors);
			if (!isErrored) {
				return <ErrorField />;
			}
			return keys.map(key =>
				errors[key] ? <ErrorField key={key} errorText={errors[key]} /> : null,
			);
		}
	};
	return (
		<Fragment>
			<InputContainer isErrored={isErrored} styles={styles}>
				<InputField
					type={type}
					name={name}
					value={value}
					empty={isEmpty}
					onChange={e => onChange(name, e)}
					onBlur={() => onBlur(name)}
					onFocus={e => onFocus(name, e)}
					onClick={onClick}
					autoComplete={type === 'password' ? 'new-password' : 'off'}
				/>
				<Label htmlFor={name} empty={isEmpty}>
					{label}
				</Label>
				{type === InputTypes.email && <EmailIcon />}
				{type === InputTypes.password && <PasswordIcon />}
			</InputContainer>
			{type === 'password' && showSuggestion && (
				<PasswordSuggestion>
					Password should have at least one letter.
				</PasswordSuggestion>
			)}
			{errorMessages()}
		</Fragment>
	);
};

Input.propTypes = {
	type: PropTypes.oneOf([
		InputTypes.text,
		InputTypes.email,
		InputTypes.password,
	]),
	placeholder: PropTypes.string,
	label: PropTypes.string,
	styles: PropTypes.shape({}),
	isRequired: PropTypes.bool,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onCLick: PropTypes.func,
	errors: PropTypes.shape({}),
};

Input.defaultProps = {
	type: InputTypes.text,
	label: '',
	field: {
		name: '',
		value: '',
	},
	form: {
		touched: {},
		errors: {},
	},
	onFocus: () => {},
	onBlur: () => {},
	onClick: () => {},
};

export default Input;

/*----------------- Styles -----------------*/
const InputContainer = styled('div')`
	width: 100%;
	position: relative;
	border: 1px solid
		${({ isErrored, theme }) => (isErrored ? theme.errorColor : '#aaa')};
	border-radius: 2px;
	overflow: hidden;

	& svg {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
	}
	${({ styles }) => styles};
`;
const InputField = styled('input')`
	width: 100%;
	border: none;
	padding: ${({ empty }) =>
		empty ? '13px 50px 13px 10px' : '22px 50px 4px 10px'};
	margin: 0;
	background: 0;
	font-size: 16px;
	line-height: 24px;
	outline: none;
	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 30px white inset !important;
	}
	&:focus ~ label {
		top: 10px;
		transform: translateY(-50%) scale(0.8);
	}
`;
const Label = styled('label')`
	position: absolute;
	top: ${({ empty }) => (empty ? '50%' : '10px')};
	left: 10px;
	transform: ${({ empty }) =>
		empty ? 'translateY(-50%)' : 'translateY(-50%) scale(0.8)'};
	color: #666;
	transition: all 0.2s;
	pointer-events: none;
`;
const PasswordSuggestion = styled.div`
	font-size: 12px;
`;
