import { all, takeEvery, call, put } from 'redux-saga/effects';
import { navigate } from '@reach/router';
import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
import {
	SERVER_ERROR_MESSAGES,
	USER_NOT_CONFIRMED_EXEPTION,
} from '../constants';
import { appName } from '../../../../config/app.config';
import authApi from '../utils/authAPI';

export const moduleName = 'auth';
const prefix = `${appName}/${moduleName}`;

// Constants
export const GET_POOL_DATA_STARTED = `${prefix}/GET_POOL_DATA_STARTED`;
export const GET_POOL_DATA_SUCCESS = `${prefix}/GET_POOL_DATA_SUCCESS`;
export const GET_POOL_DATA_FAIL = `${prefix}/GET_POOL_DATA_FAIL`;
export const REGISTER = `${prefix}/REGISTER`;
export const REGISTER_STARTED = `${prefix}/REGISTER_STARTED`;
export const REGISTER_SUCCESS = `${prefix}/REGISTER_SUCCESS`;
export const REGISTER_FAIL = `${prefix}/REGISTER_FAIL`;
export const REGISTER_CONFIRM = `${prefix}/REGISTER_CONFIRM`;
export const REGISTER_CONFIRM_STARTED = `${prefix}/REGISTER_CONFIRM_STARTED`;
export const REGISTER_CONFIRM_SUCCESS = `${prefix}/REGISTER_CONFIRM_SUCCESS`;
export const REGISTER_CONFIRM_FAIL = `${prefix}/REGISTER_CONFIRM_FAIL`;
export const LOGIN_USER = `${prefix}/LOGIN_USER`;
export const LOGIN_USER_STARTED = `${prefix}/LOGIN_USER_STARTED`;
export const LOGIN_USER_SUCCESS = `${prefix}/LOGIN_USER_SUCCESS`;
export const LOGIN_USER_FAIL = `${prefix}/LOGIN_USER_FAIL`;
export const LOGOUT_USER = `${prefix}/LOGOUT_USER`;
export const LOGOUT_USER_STARTED = `${prefix}/LOGOUT_USER_STARTED`;
export const LOGOUT_USER_SUCCESS = `${prefix}/LOGOUT_USER_SUCCESS`;
export const LOGOUT_USER_FAILURE = `${prefix}/LOGOUT_USER_FAILURE`;
export const SESSION_VALIDITY = `${prefix}/SESSION_VALIDITY`;
export const SESSION_VALIDITY_STARTED = `${prefix}/SESSION_VALIDITY_STARTED`;

// Selectors
const selectAuth = state => state.get(moduleName);
export const loading = () =>
	createSelector(
		selectAuth,
		authState => authState.get('loading'),
	);
export const loggedIn = () =>
	createSelector(
		selectAuth,
		authState => authState.get('loggedIn'),
	);
export const errorMessage = () =>
	createSelector(
		selectAuth,
		authState => authState.get('errorMessage'),
	);
export const isSubmitting = () =>
	createSelector(
		selectAuth,
		authState => authState.get('isSubmitting'),
	);

// Reducer
export const initialState = fromJS({
	loading: false,
	registrationConfirmed: false,
	errorMessage: '',
	tokenId: undefined,
	isSubmitting: false,
});

export default function(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_POOL_DATA_STARTED:
		case LOGOUT_USER_STARTED:
		case SESSION_VALIDITY_STARTED:
			return state.set('loading', true).set('errorMessage', '');
		case LOGIN_USER_STARTED:
		case REGISTER_STARTED:
		case REGISTER_CONFIRM_STARTED:
			return state.set('isSubmitting', true);
		case GET_POOL_DATA_SUCCESS:
			return state.set('loading', false).set('errorMessage', '');
		case REGISTER_SUCCESS:
			return state.set('isSubmitting', false).set('errorMessage', '');
		case REGISTER_FAIL:
			return state.set('isSubmitting', false).set('errorMessage', payload);
		case GET_POOL_DATA_FAIL:
		case LOGOUT_USER_FAILURE:
			return state.set('loading', false).set('errorMessage', payload);
		case REGISTER_CONFIRM_SUCCESS:
			return state
				.set('isSubmitting', false)
				.set('errorMessage', '')
				.set('registrationConfirmed', true);
		case REGISTER_CONFIRM_FAIL:
			return state
				.set('isSubmitting', false)
				.set('errorMessage', payload)
				.set('registrationConfirmed', false);
		case LOGIN_USER_SUCCESS:
			return state
				.set('isSubmitting', false)
				.set('errorMessage', '')
				.set('loggedIn', true);
		case LOGIN_USER_FAIL:
			return state
				.set('isSubmitting', false)
				.set('errorMessage', payload)
				.set('loggedIn', false);
		case LOGOUT_USER_SUCCESS:
			return fromJS({});
		case SESSION_VALIDITY:
			return state
				.set('loading', false)
				.set('loggedIn', payload)
				.set('errorMessage', '');
		case 'PATH_CHANGED':
			return state.set('errorMessage', '');
		default:
			return state;
	}
}

// Action creators
/**
 * @param { Object } userInfo
 * @param { String } userInfo.username
 * @param { String } userInfo.email
 * @param { String } userInfo.password
 * @returns {{ type: String, payload: Object }}
 */
export const registerUser = userInfo => ({
	type: REGISTER,
	payload: userInfo,
});

/**
 * @param { String } code
 * @returns {{ type: String, payload: String }}
 */
export const confirmRegistration = code => ({
	type: REGISTER_CONFIRM,
	payload: code,
});

/**
 *  @param {{ username: String, password: String }} credentials
 * @returns {{ type: String, payload: { username: String, password: String }}}
 */
export const loginUser = credentials => ({
	type: LOGIN_USER,
	payload: credentials,
});

/**
 * @returns {{ type: String }}
 */
export const logoutUser = () => ({
	type: LOGOUT_USER,
});

// Sagas
export function* checkSessionValidity() {
	yield put({ type: SESSION_VALIDITY_STARTED });
	try {
		const session = yield call(authApi.getCurrentUser, null);
		yield put({ type: SESSION_VALIDITY, payload: session.isValid() });
	} catch (e) {
		yield put({ type: SESSION_VALIDITY, payload: false });
		console.error(
			`checkSessionValidity saga failure with error: ${e.message || e}`,
		);
	}
}

export function* getPoolData() {
	yield put({ type: GET_POOL_DATA_STARTED });
	try {
		const poolData = yield call(authApi.fetchPoolData, null);
		yield call(authApi.setUserPool, {
			UserPoolId: poolData && poolData.poolId,
			ClientId: poolData && poolData.clientId,
			Region: poolData && poolData.region,
		});
		yield put({ type: GET_POOL_DATA_SUCCESS });
		yield call(checkSessionValidity, null);
	} catch (e) {
		yield put({
			type: GET_POOL_DATA_FAIL,
			payload: e.message,
		});
		console.error(`getPoolData failed with error: ${e}`);
	}
}

export function* registerSaga({ payload }) {
	yield put({ type: REGISTER_STARTED });
	try {
		yield call(authApi.registerUser, payload);
		yield put({
			type: REGISTER_SUCCESS,
		});
		navigate('/registerConfirm');
	} catch (err) {
		const { code, message } = err;
		yield put({
			type: REGISTER_FAIL,
			payload: SERVER_ERROR_MESSAGES[code] || message,
		});
		console.error(`registerSaga failed with error: ${err.message || err}`);
	}
}

export function* registerConfirmSaga({ payload }) {
	yield put({ type: REGISTER_CONFIRM_STARTED });
	try {
		yield call(authApi.confirmRegistration, payload);
		yield put({ type: REGISTER_CONFIRM_SUCCESS });
		navigate('/login');
	} catch (err) {
		const { code, message } = err;
		yield put({
			type: REGISTER_CONFIRM_FAIL,
			payload: SERVER_ERROR_MESSAGES[code] || message,
		});
		console.error(
			`registerConfirmSaga failed with error: ${SERVER_ERROR_MESSAGES[code] ||
				message}`,
		);
	}
}

export function* loginSaga({ payload }) {
	yield put({ type: LOGIN_USER_STARTED });
	try {
		const result = yield call(authApi.authenticateUser, payload);
		const { idToken } = result;
		yield call(authApi.setIdToken, idToken);
		yield put({
			type: LOGIN_USER_SUCCESS,
			payload: idToken,
		});
		navigate('/');
	} catch (err) {
		const { code, message } = err;
		console.error(
			`loginSaga failure with error: ${SERVER_ERROR_MESSAGES[code] || message}`,
		);
		yield put({
			type: LOGIN_USER_FAIL,
			payload: SERVER_ERROR_MESSAGES[code] || message,
		});
		if (code === USER_NOT_CONFIRMED_EXEPTION) {
			navigate('/registerConfirm');
		}
	}
}

export function* logoutSaga() {
	yield put({ type: LOGOUT_USER_STARTED });
	try {
		yield call(authApi.logoutUser);
		yield put({
			type: LOGOUT_USER_SUCCESS,
		});
		navigate('/');
	} catch (err) {
		console.error(`logoutSaga failure with error: ${err.message || err}`);
		yield put({
			type: LOGOUT_USER_FAILURE,
			payload: err.message,
		});
	}
}

export function* register() {
	yield takeEvery(REGISTER, registerSaga);
}

export function* registerConfirm() {
	yield takeEvery(REGISTER_CONFIRM, registerConfirmSaga);
}

export function* login() {
	yield takeEvery(LOGIN_USER, loginSaga);
}

export function* logout() {
	yield takeEvery(LOGOUT_USER, logoutSaga);
}

export function* authRootSaga() {
	yield all([getPoolData(), register(), registerConfirm(), login(), logout()]);
}
