import request from './request';
import getQuery from './constructUrl';
import { urlBases } from './constants';

const getWithQuery = (baseUrl, query) => {
	if (!baseUrl) {
		throw new Error(`baseUrl is not provided for ${query}`);
	} else if (!urlBases[baseUrl]) {
		throw new Error(`baseUrl: ${baseUrl} is not in the urlBases list`);
	}
	return request({
		url: `${urlBases[baseUrl]}${getQuery(query)}`,
		method: 'GET',
		authRequired: true,
	}).then(response => {
		if (response) {
			return response.json();
		}
		return [];
	});
};

const addRequest = (baseUrl, body) => {
	if (!baseUrl) {
		throw new Error(`baseUrl is not provided`);
	} else if (!urlBases[baseUrl]) {
		throw new Error(`baseUrl: ${baseUrl} is not in the urlBases list`);
	}
	return request({
		method: 'POST',
		url: urlBases[baseUrl],
		body: JSON.stringify(body),
		authRequired: true,
	}).then(response => response.json());
};

const getBy = (baseUrl, param) => {
	if (!param) {
		return undefined;
	}
	if (!baseUrl) {
		throw new Error(`baseUrl is not provided`);
	} else if (!urlBases[baseUrl]) {
		throw new Error(`baseUrl: ${baseUrl} is not in the urlBases list`);
	}
	if (typeof param !== 'string') {
		throw new Error('param in getBy must be of type string');
	}
	return request({
		method: 'GET',
		authRequired: true,
		url: `${urlBases[baseUrl]}/${param}`,
	}).then(response => response.json());
};

const deleteBy = (baseUrl, param) => {
	if (!param) {
		return undefined;
	}
	if (!baseUrl) {
		throw new Error(`baseUrl is not provided`);
	} else if (!urlBases[baseUrl]) {
		throw new Error(`baseUrl: ${baseUrl} is not in the urlBases list`);
	}
	if (typeof param !== 'string') {
		throw new Error('param in deleteBy must be of type string');
	}
	return request({
		method: 'DELETE',
		authRequired: true,
		url: `${urlBases[baseUrl]}/${param}`,
	}).then(response => {
		if (response.status === 200) {
			return response.json();
		}
	});
};

const editRequest = (baseUrl, body, id) => {
	if (!baseUrl) {
		throw new Error(`baseUrl is not provided`);
	} else if (!urlBases[baseUrl]) {
		throw new Error(`baseUrl: ${baseUrl} is not in the urlBases list`);
	}
	return request({
		method: 'PUT',
		url: `${urlBases[baseUrl]}/${id}`,
		body: JSON.stringify(body),
		authRequired: true,
	}).then(response => response.json());
};

export { getWithQuery, addRequest, getBy, deleteBy, editRequest };
